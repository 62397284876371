.aditionalLinkBox {
    border: 1px solid #d8d8d8;
    padding: 20px 10px;
    text-align: center;
    border-radius: 5px;
    background: #002c56;
    color: #fff;
    text-decoration: none;
    margin: 15px 0px 0px 0px;
    box-shadow:1px 2px 3px #2380cc;
}
.aditionalLinkBoxchild {
    border: 1px solid #d8d8d8;
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    background: #2380cc;
    color: #fff;
    text-decoration: none;
    margin: 0px 0px 0px 0px;
}
.facebookSocial{
    margin-bottom:10px;
}
.facebookSocial h5 {
    color: #fff;
    text-align: left;
    background: #1264b3;
    margin: 0px 0px 0px 0px;
    padding: 10px 5px 13px 5px;
    font-size: 18px;
    border-bottom: 1px solid #2b2727;
}
.facebookiconlink {
    /*background: #1b7ece;*/
    padding: 5px 9px;
    border-radius: 50%;
    color: #ffffff;
    margin-bottom: 5px;
}
