@tailwind base;
@tailwind components;
@tailwind utilities;


.uploadcare--widget__button {
    /* background-color: #000000; */
    color: black;
    font-size: 13px;
    border: 1px solid gray;
    padding: 2px 10px;
    background-color: rgb(224, 224, 224);
    
}
.uploadcare--widget__button:hover {
    /* background-color: #000000; */
    color: black;
    font-size: 13px;
    border: 1px solid gray;
    padding: 2px 10px;
    background-color: rgb(224, 224, 224);
    
}
.uploadcare--widget__button_type_open{
    color: black;
    font-size: 13px;
    border: 1px solid gray;
    padding: 2px 10px;
    background-color: rgb(224, 224, 224);
}
.uploadcare--widget__button_type_open:hover{
    color: black;
    font-size: 13px;
    border: 1px solid gray;
    padding: 2px 10px;
    background-color: rgb(224, 224, 224);
}
.uploadcare--button{
    background-color: rgb(0, 2, 95);
}
.uploadcare--widget__drag{
color: black;
    background-color: #000000;

}


/* uploadcare--widget__button uploadcare--widget__button_type_open */