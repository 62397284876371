/* cyrillic-ext */

/* cyrillic */

/* greek-ext */
/* greek */
@font-face {
    font-family: 'Open Sans Condensed' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), 
    /* url(fonts/OpenSansCondensed-Bold.ttf)  */
    format('woff2');
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: 'Open Sans Condensed' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), 
    /* url(fonts/OpenSansCondensed-Light.ttf)  */
    format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Open Sans Condensed' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), 
    /* url(fonts/OpenSansCondensed-LightItalic.ttf)  */
    format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */


