/* *{
    font-family: Montserrat, sans-serif;
    letter-spacing: 0.5px;
} */
.bootstrap-form{
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 0.5rem;
}

.paymentPage{
    font-family: Montserrat, sans-serif;
    letter-spacing: 0.5px;
}
.applicationCSS{
    margin: 1rem 3rem;
}
.applicationCSS2{
    margin: auto;
}
@media only screen and (max-width: 600px) {
    .upperNav{
        width: 120%;
        /* border: 1px solid black; */
    }
    .applicationCSS{
        width: 120%;
        margin: 0;
    }
    .applicationCSS3{
        margin: auto;
        /* border: 1px solid red; */
        width: 120%;
        /* margin: auto; */
    }
    .applicationCSS4{
        width: 120%;
    }



    .upperNav2{
        /* border: 1px solid black; */
        padding-left: 0;
        width: 150%;
    }
}